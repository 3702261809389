import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const SquareLink = ({ to = "/", image = null, className = "" }) => {
  return (
    <Link
      to={to}
      className={`flex flex-col items-center justify-center mx-2  md:mx-5 mb-2 w-24 xs:w-28 sm:w-32 md:w-40 lg:w-48 xl:w-56 shadow ${className}`}
    >
      <GatsbyImage
        image={image}
        className="w-full transform  hover:scale-105"
      />
    </Link>
  )
}

export default SquareLink
