import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import React from "react"

const Poster = ({ fluid = null }) => {
  const data = useStaticQuery(graphql`
    query PosterQuery {
      poster: file(relativePath: { eq: "AllChildrenMatter-Flyer.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1500
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
  `)

  const image = getImage(data.poster)

  return (
    <div className="w-full px-5 flex justify-center flex-wrap my-5">
      <GatsbyImage
        image={image}
        className="block min-h-15rem max-w-xl w-full  h-full mr-3 m-3"
      />
    </div>
  )
}

export default Poster
